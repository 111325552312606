// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `angular-cli.json`.

import {IProductEnvironment} from "./FacilitiesEvaluationEnvironment";
import {EProductType} from "../javascript.lib.mojo-base/model/ProductType";
import {FacilityAccessibilityProduct4} from "../product.facility/model/FacilityAccessibilityProduct4";

export const environment: IProductEnvironment = {

  name: 'PwC: Facilities',
  filename: 'environment.facilities-808767.ts',

  isDevelopment: false,
  isProduction: true,
  isTest: false,

  authenticationConfig: {

    emailLink: true,
    textMessage: false,
    usernamePassword: false,
  },

  firebaseConfig: {
    apiKey: "AIzaSyC63BF75HwtU9e9vBQzC4ogEOF9kD_BTzk",
    authDomain: "facilities-808767.firebaseapp.com",
    databaseURL: "https://facilities-808767-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "facilities-808767",
    storageBucket: "facilities-808767.appspot.com",
    messagingSenderId: "612736155944",
    appId: "1:612736155944:web:95a7590c598e27ab61d01d"
  },

  // curl -iLS https://us-central1-$_TARGET_ENV.cloudfunctions.net/public/functions/info
  cloudFunctionsUrl: 'https://us-central1-facilities-808767.cloudfunctions.net',

  nocoDbConfig: {
    // httpServer: 'http://localhost:8080',
    httpServer: 'https://mobilitymojo-spot.com',
    user: 'test@mobilitymojo.com',
    password: 'test@mobilitymojo.com',
    authorization: 'Basic bW9iaWxpdHk6bW9qbw==',
    projectTitle: '2024 - Grosvenor and The Crown Estate',
  },

  productConfig: {

    firebaseAnswersRoot: '/answers-x/facilities',
    product:  FacilityAccessibilityProduct4.INSTANCE,
    productType: EProductType.facilities,
    canCommentOnClusters: true,
    canEditClusters: false,
  },

  preview: {

    fireworksEnabled: true,
    reportGeneration2: true,
    clusterSupport: true,
  },



};
